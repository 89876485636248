<template>
  <v-container >
    <v-row dense class="mt-2">
      <v-snackbar
        v-model="snackbar"
        :timeout="3000"
        top
        color="success">
        {{text}}
        <v-btn
          color="white"
          text
          @click="snackbar = false"
        >Close
      </v-btn>
     </v-snackbar>

    </v-row>

    <v-row>
      <v-col cols="12" md="6" sm="6" xs="12" class="ma-0 py-2">
        <v-card class="elevation-5 ma-1 px-1" >
          <v-card-title >
            <div class="bue--text" >Fecha: {{newFecha}}</div>
            <v-spacer></v-spacer>
            <div class="red--text" >Cotización: {{docant.Numdoc}}</div>
          </v-card-title>
        </v-card>

        <v-card class="elevation-2 ma-1 px-1"  >

          <v-card-title
            class="ma-0 py-0">Datos del Pago en     {{moneda}}
          </v-card-title>

          <v-card-text>
            <table width="100%">
              <tr>
                <th style="text-align:left;">IMPORTE: </th>
                <th style="text-align:right;">{{ getTotales.importe}}</th>
              </tr>
              <tr>
                <th style="text-align:left;">DESCUENTO: </th>
                <th style="text-align:right;">{{ getTotales.descuento}}</th>
              </tr>

              <tr>
                <th style="text-align:left;">SUBTOTAL: </th>
                <th style="text-align:right;">{{ getTotales.subtotal}}</th>
              </tr>
              <tr>
                <th style="text-align:left;">IVA: </th>
                <th style="text-align:right;">{{ getTotales.iva}}</th>
              </tr>
              <tr>
                <th style="text-align:left;">TOTAL: </th>
                <th style="text-align:right;">{{ getTotales.total}}</th>
              </tr>
            </table>
          </v-card-text>
        </v-card>

        <v-card class="elevation-2 ma-1 px-1">
          <v-card-title
            class="ma-1 py-1">Datos del Cliente
          </v-card-title>

          <v-card-text >
              <div><strong>{{ Clientes.nomcli }}</strong></div>
              <div><strong>{{ Clientes.Rfc }}</strong></div>
              <div>{{ Clientes.Calle }}  {{Clientes.Numext}}. {{Clientes.Numint}} {{Clientes.colonia}} </div>
              <div>{{ Clientes.Ciudad }}  {{Clientes.Estado}} {{Clientes.Cp}}  </div>
              <div>Atención :{{ Clientes.atvent}}  </div>
                  <div> Email :{{Clientes.email1}} </div>
              <div>{{ Clientes.telefono}}</div>
          </v-card-text>
        </v-card>

        <v-card class="elevation-2 ma-1 px-1" >

          <v-card-title
            v-if="Recogeentienda"
            class="ma-0 py-0">Recoge en Tienda
          </v-card-title>

          <v-card-title
            v-else class="ma-0 py-0">Dirección de Envío
          </v-card-title>

          <v-card-text
            class="pa-0 ma-0"
            v-if="mismaDireccion">
              <div>Atención: {{ Clientes.atvent}}   </div>
              <div>{{ Clientes.Calle }}  {{Clientes.Numext}}. {{Clientes.Numint}} {{Clientes.colonia}} </div>
              <div>{{ Clientes.Ciudad }}  {{Clientes.Estado}} {{Clientes.Cp}}  </div>
              <div>{{ Clientes.telefono}}</div>
          </v-card-text>

          <v-card-text class="ma-0 py-0" v-else>
              <div>{{ Direnvio}}</div>
          </v-card-text>

        </v-card>
      </v-col>

      <v-col cols="12" md="6" sm="6" xs="12" class="ma-0 py-2">
        <v-card class="elevation-2 ma-1 pa-1">

          <v-card-title
            class="ma-1 py-1">Pago con tarjeta por  $
              <span class="red--text text-right" >  {{ getTotales.total }}  {{moneda}} </span>
          </v-card-title>

          <v-card-text >
            <v-row align="center" justify="center">
              <v-img
                src="@/assets/openpay/cards1.png"
                aspect-ratio="1.7"
                contain
                height="30"
              ></v-img>
            </v-row>

            <v-form
              ref="form5"
              v-model="valid"
              lazy-validation class="pt-1">
              <v-container grid-list-md>

                <v-text-field
                  prepend-icon="person"
                  label="Nombre del titular"
                  v-model="name_titular"
                  type="text"
                  required
                  :rules="[v => !!v || 'El nombre del titular es requerido']"
                  autocomplete="off"
                ></v-text-field>

                <v-text-field
                  prepend-icon="person"
                  label="Apellido del titular"
                  v-model="last_name_titular"
                  type="text"
                  required
                  :rules="[v => !!v || 'El nombre del titular es requerido']"
                  autocomplete="off"
                ></v-text-field>

               <v-text-field
                  prepend-icon="payment"
                  name="card"
                  label="Número de tarjeta"
                  type="text"
                  :mask="mask_credit_card"
                  v-model="number_card"
                  required
                  :rules="[v => !!v || 'El número de tarjeta es requerido']"
                  autocomplete="off"
                ></v-text-field>

                <v-text-field
                  prepend-icon="mail"
                  name="email"
                  label="Correo electrónico"
                  type="email"
                  v-model="email"
                  required
                  autocomplete="off"
                  :rules="emailRules"
                ></v-text-field>

                <v-row>
                  <v-col cols="5" class="ma-0 pa-0">
                    <v-select
                      prepend-icon="fa-calendar-o"
                      :items="items_months"
                      v-model="month_expiration"
                      label="Mes"
                      required
                      :rules="[v => !!v || 'El mes de expiración es requerido']"
                    ></v-select>
                  </v-col>

                  <v-col cols="3" class="ma-0 pa-0">
                    <v-text-field
                        prepend-icon="fa-calendar-o"
                        label="Año"
                        v-model="year_expiration"
                        :rules="rules_year"
                        type="number"
                        required
                    ></v-text-field>
                  </v-col>

                  <v-col cols="3" class="ma-0 pa-0">
                      <v-text-field
                        prepend-icon="fa-credit-card-alt"
                        name="cvc"
                        label="CVC"
                        type="number"
                        v-model="cvv2"
                        :rules="[rules_cvc.required, rules_cvc.min, rules_cvc.max]"
                        required
                        autocomplete="off"
                      ></v-text-field>
                  </v-col>
                </v-row>

                <v-row align-center justify-center  class="pt-2">
                  <!--     <v-img
                    src="@/assets/openpay/security.png"
                    aspect-ratio="1.7"
                    contain
                    height="25"
                  ></v-img> -->

                  <v-img
                    src="@/assets/openpay/openpay.png"
                    aspect-ratio="1.7"
                    contain
                    height="30"
                  ></v-img>

                  <v-btn  :color="colorButton"
                    class="white--text text-capitalize"
                    @click="pay"
                    :disabled="!valid">
                    {{ mesageButton }}
                    <v-icon>{{ iconButton }}</v-icon>
                  </v-btn>
                </v-row>
              </v-container>
            </v-form>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import axios from 'axios'
var base64 = require('base-64')
var accounting = require('accounting')

export default {
  data: () => ({
    // DATOS DEL PAGO
    totaldoc: 0.00,
    tokenSait: '',
    email1: '',
    email2: '',
    Recogeentienda: false,
    tipopago: '',
    mismaDireccion: true,
    Direnvio: '',
    docant: '',
    Clientes: '',
    credito: false,
    mensaje: '',

    direccion: '',
    totales: {},

    fecha: new Date().toISOString().substr(0, 10),
    // fecha:  new Date             comprobanteModal: false,
    saldo: 0,
    dias: 0,
    snackbar: false,
    text: '',
    paidFor: false,
    loadding: false,
    totalpagar: '',
    cart: '',

    res: '',
    moneda: '',
    invoicesNumber: '',

    valid: true,
    number_card: '',
    mask_credit_card: 'credit-card',
    rules_cvc: {
      required: v => !!v || 'El cvc es requerido',
      min: v => (v && v.length >= 3) || 'Mínimo 3 números',
      max: v => (v && v.length <= 4) || 'Máximo 4 números'
    },
    items_months: [
      { text: '01 - Enero', value: '01' },
      { text: '02 - Febrero', value: '02' },
      { text: '03 - Marzo', value: '03' },
      { text: '04 - Abril', value: '04' },
      { text: '05 - Mayo', value: '05' },
      { text: '06 - Junio', value: '06' },
      { text: '07 - Julio', value: '07' },
      { text: '08 - Agosto', value: '08' },
      { text: '09 - Septiembre', value: '09' },
      { text: '10 - Octubre', value: '10' },
      { text: '11 - Noviembre', value: '11' },
      { text: '12 - Diciembre', value: '12' }
    ],

    holder_name: '',
    name_titular: '',

    email: '',
    last_name_titular: '',
    month_expiration: '',
    year_expiration: '',
    cvv2: '',
    rules_year: [
      v => !!v || 'El año de expiración es requerido',
      v => (v && v.length >= 2) || 'Mínimo 2 números',
      v => (v && v.length <= 2) || 'Máximo 2 números'
    ],
    emailRules: [
      v => !!v || 'El correo electrónico es requerido',
      v => /.+@.+/.test(v) || 'El correo electrónico debe de ser valido'
    ],

    token: '',
    endpoint_sandbox_openpay: 'https://sandbox-api.openpay.mx/v1/',
    endpoint_payament: 'auth/api/v1/cargosop',

    deviceSessionId: '',
    openpay_id: 'mnatxz2v9se7bk0oz2j4',
    openpay_key: 'pk_ef9092e83bd343558d06a859195548ca',

    openpay_sandbox_mode: true,
    mesageButton: 'Pagar',
    iconButton: 'attach_money',
    colorButton: 'secondary'
  }),

  computed: {
    ...mapGetters('Login', ['getClientes', 'getdatosUsuario']),
    ...mapGetters('documento', ['getDocumento', 'getTotales']),

    newFecha (fecha) {
      return fecha ? this.$moment(fecha).format('DD-MMM-YYYY') : ''
    }

  },

  created () {
    this.totalpagar = this.getTotales.total
    // console.log("totalpagar",this.totalpagar)

    this.totaldoc = parseFloat(accounting.unformat(this.totalpagar))
    // console.log("totaldoc",this.totaldoc)
    // this.totaldoc = parseFloat(this.totalpagar)
    // console.log("totaldoc",this.totaldoc)
    this.tipopago = this.$route.params.tipo
    // console.log("this.getTotales",this.getTotales)

    // this.validaCredito()
    this.Iddocum = this.getDocumento.Iddocum
    console.log('DOCUM', this.getDocumento)

    // this.tipoPago = this.$route.params
    this.Clientes = this.getClientes
    console.log('clientes', this.getClientes)

    // PARA QUE ES ESTA API? por que no se corrige el original.
    this.$http.get('api/v1/email.list').then(response => {
      // console.log(response)
      this.email1 = response.body.Email1
      this.email2 = response.body.Email2
    }).catch(err => { console.log(err) })

    // CONSULTAR ORDER
    this.$http.get('api/v1/order.info/' + this.Iddocum).then(response => {
      this.docant = response.body
      // console.log("Doumento", this.docant)
      this.invoicesNumber = this.docant.Numdoc
      // console.log(this.docant)
      if (this.docant.divisa == 'P') {
        this.moneda = 'MXN'
      } else {
        this.moneda = 'USD'
      }

      if (this.docant.numclisuc == '') {
        this.mismaDireccion = true
      } else {
        this.mismaDireccion = false
        this.Direnvio = this.docant.Direnvio
      }

      if (this.docant.Direnvio.trim() != '' && this.docant.Numclisuc != '') {
        this.Recogeentienda = false
      } else {
        this.Recogeentienda = true
      }
    }).catch(err => { console.log(err) })

    // LOGIN DE API SAITNUBE
    if (process.env.VUE_APP_SAIT_NUBE !== '') {
      var Payload = {
        numuser: process.env.VUE_APP_UN,
        password: process.env.VUE_APP_PW
      }

      // API AXIOS SAIT NUBE
      var url = process.env.VUE_APP_SAIT_NUBE
      var uri = url + '/api/v1/login'
      // var url = 'https://sait-md3.saitnube.com/api/v1/login'

      console.log('SAIT NUBE uri', uri, Payload)

      axios.post(uri, Payload, { headers: { 'Content-Type': 'application/json' } }).then(response => {
        // console.log("Sait NUBE",response.data)
        this.tokenSait = response.data.token
        console.log(this.tokenSait)
        localStorage.setItem('tokensait', this.tokenSait)
      }).catch(error => { console.log(error) })
    }
  },

  mounted () {
    // sistema de Anti fraude
    OpenPay.setId(this.openpay_id)
    OpenPay.setApiKey(this.openpay_key)
    OpenPay.setSandboxMode(this.openpay_sandbox_mode)
    this.deviceSessionId = OpenPay.deviceData.setup()
    console.log('deviceSessionId', this.deviceSessionId)
  },

  methods: {
    pay () {
      // generate token para realizar el cargo
      OpenPay.token.create({
        holder_name: this.name_titular + ' ' + this.last_name_titular,
        // "holder_name": this.holder_name,
        card_number: this.number_card,
        cvv2: this.cvv2,
        expiration_month: this.month_expiration,
        expiration_year: this.year_expiration
      }, (response) => {
        console.log('token create', response)
        this.token = response.data.id
        this.llamarpago()
      }, (error) => {
        console.log(error)
      })
    },

    // METODOS DEL PAGO
    llamarpago () {
      console.log('Token ', this.token)
      console.log('amount', this.totaldoc)

      var payloadOp = {
        // token:  this.token,
        source_id: this.token,
        method: 'card',
        amount: this.totaldoc,
        currency: this.moneda,
        order_id: this.docant.Numdoc.trim(),
        device_session_id: this.deviceSessionId,
        customer: {
          name: this.name_titular,
          last_name: this.last_name_titular,
          // holder_name:  this.name_titular + ' ' + this.last_name_titular,
          email: this.email
        }
      }

      console.log('Payload', payloadOp)

      // PASO 3. Envio de Datos( Datos del Cobro, token. y deviceSessionid)
      this.$http.post(this.endpoint_payament, payloadOp).then(response => {
        if (response.status = 200) {
          this.mesageButton = 'Pagado'
          this.loadingPay = false
          this.iconButton = 'check_circle'
          this.colorButton = 'red'

          this.text = 'Pedido generado correctamente'
          this.snackbar = true
          console.log(response)
          // MANDA PROCESAR EL PEDIDO
          this.saveinfo()
        } else {
          this.text = 'Problemas al generar tu pago.'
          this.snackbar = true
          console.log('', response)
        }
      }).catch(error => {
        console.log('Hubo un problema con tu pago')
        console.log('error: ', error)
      })
    },

    gettoken () {
      console.log('GetToken Papal en BackEnd')
    },

    horaSait () {
      var f = new Date()
      var hora = f.getHours()
      var minuto = f.getMinutes()
      var segundo = f.getSeconds()
      if (hora < 10) {
        hora = '0' + f.getHours()
      }
      if (minuto < 10) {
        minuto = '0' + f.getMinutes()
      }
      if (segundo < 10) {
        segundo = '0' + f.getSeconds()
      }
      return hora + ':' + minuto + ':' + segundo
    },

    enviarCorreo (documento1) {
      console.log('this.docant', this.docant)
      var correo = ''
      if (this.getdatosUsuario.numcli) {
        correo = this.getdatosUsuario.Email
      } else {
        correo = this.CargaCliente.email1
      }

      if (this.$store.state.tipomoneda == 'P') {
        this.moneda = 'MXN'
      } else {
        this.moneda = 'USD'
      }

      console.log('documento1.numdoc', documento1[0].numdoc)
      // La respuesta de order.add incluye el iddocum del generado
      var iddocum = base64.encode(documento1[0].iddocum)
      // var iddocum = base64.encode(param[0].iddocum);

      var url = this.$http.options.root.replace('tienda3', 'consultacoti/')

      this.CargaCliente = this.getClientes
      console.log(this.CargaCliente)
      console.log('correo', correo)

      var payload = {
        email: correo,
        subject: 'Pedido Tienda en Linea ',
        info1: 'Pedido',
        folio: documento1[0].numdoc.trim(),
        info3: 'Se ha elaborado un pedido con el folio: ',
        nombre: this.getdatosUsuario.Nomuser,
        direccion: '',
        fecha: this.fecha,

        telefono: this.getdatosUsuario.Telefono,
        divisa: this.moneda,

        rfc: this.CargaCliente.Rfc,

        subtotal: this.getTotales.subtotal,
        iva: this.getTotales.iva,
        total: this.getTotales.total,

        c2a_link: url + iddocum,
        hora: this.horaSait(),
        logo: this.logo,

        color: this.$vuetify.theme.themes.light.secondary,
        email1: this.email1,
        email2: this.email2
      }

      console.log(payload)
      // Enviar el correo
      this.$http.post('auth/api/v1/sendcot', payload).then(response => {
        console.log('enviando Correo')
        // var doc = {doc: iddocum}
        // this.$router.push({name:'documento', params: {info: doc}})
      }).catch(error => { console.log(error) })
    },

    validainfo () {
      // AQUI SE DEBEN DE PONER LAS
    },

    saveinfo () {
      var me = this
      var documento = this.docant
      documento.status = 9

      var payload = {
        status: 9,
        Tipodoc: this.docant.Tipodoc,
        Numdoc: this.docant.Numdoc
      }
      // console.log(payload)
      // actualizar el estatus
      this.$http.put('auth/api/v1/updatestatus', payload).then(response => {
        // console.log(response)
      }).catch(error => { console.log(error) })

      /// ///////////////////////////////////
      // Agregarmos el pedido de esa cotización

      documento.Direnvio = documento.Direnvio
      documento.Tipodoc = ' P'
      documento.Fechapago = this.fecha
      documento.Fecha = this.fecha
      documento.Refer = documento.Numdoc
      documento.status = 0

      var HoraPed = this.horaSait()

      console.log('Documento a convertir a Pedido', documento)

      this.$http.post('api/v1/order.add', documento).then(response => {
        this.text = 'Pedido generado correctamente'
        this.snackbar = true
        console.log('order add', response.body)
        this.enviarCorreo(response.body)

        setTimeout(function () { me.$router.push({ name: 'cotizaciones' }) }, 3000)
      }).catch(function (error) {
        console.log(error)
      })

      // console.log("DOCUMENTO", documento)

      var items = []
      var i = 1

      documento.Movim.forEach((element) => {
        items.push({
          tipodoc: ' P',
          numdoc: 'A1',
          numpar: i,
          numart: element.Numart,
          desc: 'Manuel',
          pend: element.Cant,
          pjedesc: element.Pjedesc,
          cant: element.Cant,
          unidad: element.Unidad,
          impuesto1: element.Impuesto1,
          impuesto2: 0,
          precio: parseFloat(element.Precio),
          obs: ''
        })
        i = i + 1
      })

      // console.log(items)
      // GRabar pedido en Sait.
      console.log(documento)
      var Pedidodoc = {
        tipodoc: documento.Tipodoc,
        numdoc: 'P55',
        numuser: '',
        nunvend: '',

        fecha: documento.Fecha,
        fentrega: documento.Fecha,
        fechacapt: documento.Fecha,

        divisa: documento.divisa,

        hora: HoraPed,
        hentrega: '',

        status: 1,
        formapago: '1',
        numalm: documento.Numalm,
        impuesto1: documento.impuesto1,
        impuesto2: 0,
        descuento: documento.descuento,
        importe: documento.Importe,
        numcli: documento.Numcli,
        pjedesc: 0,
        nomcli: '',
        direnvio: documento.Direnvio,
        tc: documento.Tc,
        obs: documento.Obs,
        items: items
      }

      // MANDAR LLAMAR PEDIDO SI TIENE API SAIT NUBE
      // API AXIOS SAIT NUBE
      if (process.env.VUE_APP_SAIT_NUBE !== '') {
        // VUE_APP_SAIT_NUBE
        console.log('SAIT NUBE PEDIDO DOC', Pedidodoc)
        console.log('TokenSait', this.tokenSait)
        var url = process.env.VUE_APP_SAIT_NUBE

        var uri = url + '/api/v1/pedidos'
        // var url = 'https://sait-md3.saitnube.com/api/v1/login'
        console.log(uri)
        // console.log("uri", uri, Payload)

        // API AXIOS SAIT NUBE
        // var url = 'https://sait-md3.saitnube.com/api/v1/pedidos'
        const urlped = url + uri
        axios.post(urlped, Pedidodoc, { headers: { 'Content-Type': 'application/json', 'x-token': this.tokenSait } }).then(response => {
          console.log('Sait NUBE', response.data)
        }).catch(error => { console.log(error) })
      }
    }
  }
}
</script>
